import React from 'react';
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import ContactsComponent from "../../components/Contacts/Contacts";

const Contacts = () => {
    return (
        <>
            <Header/>
            <ContactsComponent isAlt={true}/>
            <Footer/>
        </>
    );
};

export default Contacts;