import React from 'react';
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import AboutComponent from "../../components/About/About";
import Companies from "../../components/Companies/Companies";

const About = () => {
    return (
        <>
            <Header isAlt={true}/>
            <AboutComponent isAlt={true}/>
            <Companies isAlt={true}/>
            <Footer/>
        </>
    );
};

export default About;