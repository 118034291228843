import React from 'react';
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import ServicesComponent from "../../components/Services/Services";
const Services = () => {
    return (
        <>
            <Header/>
            <ServicesComponent isAlt={true}/>
            <Footer/>
        </>
    );
};

export default Services;