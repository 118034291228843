import React from 'react';
import s from './Companies.module.scss';

import Deja from '../../assets/images/companies/deja.png';
import ApCleaning from '../../assets/images/companies/ap-cleaning.png';
import StarsPremium from '../../assets/images/companies/starspremium.webp';
import BurgerLove from '../../assets/images/companies/burgerlove.png';
import MountAlexanderCollege from '../../assets/images/companies/MAC.png';

import classNames from "classnames";

interface ICompanies {
    isAlt?: boolean
}
const Companies = ({ isAlt }: ICompanies) => {
    return (
        <section className={classNames(s.companies, isAlt && s.companies_alt)}>
            <div className={"container"}>
                {isAlt && <h2>Our previous clients</h2>}
                <div className={s.companies__items}>
                    <a href="https://dejaboxing.com/" className={s['companies__items-item']}><img src={Deja} alt="Deja Boxing" /></a>
                    <a href="#" className={s['companies__items-item']}><img src={ApCleaning} alt="AP Cleaning" /></a>
                    <a href="https://starspremium.io" className={s['companies__items-item']}><img src={StarsPremium} alt="Stars Premium" /></a>
                    <a href="" className={s['companies__items-item']}><img src={BurgerLove} alt="Burger Love" /></a>
                    <a href="http://www.mountalexandercollege.vic.edu.au/" className={s['companies__items-item']}><img src={MountAlexanderCollege} alt="Mount Alexander College" /></a>

                </div>
            </div>
        </section>
    );
};

export default Companies;