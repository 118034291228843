import React from 'react';
import s from './Team.module.scss';
import Figure from './../../assets/images/team/figure.svg';
import LinkedIn from './../../assets/images/team/linkedin.svg';

const Team = () => {
    return (
        <section className={s.team}>
            <div className="container">
                <h2>Our <span>Team</span></h2>
                <p>Our highly experienced team is dedicated to the success of your project</p>
                <div className={s.team__items}>
                    <div className={s['team__items-item']}>

                        <h3>Nic</h3>
                        <span>Director of Operations</span>
                        <p>Manages day to day tasks needed to
                            maintain our services and business</p>
                        <a href="" className={s['team__items-item__discord']}>
                            <img src={LinkedIn} alt="LinkedIn"/>
                            <img src={Figure} alt="Figure"/>
                        </a>
                    </div>
                    <div className={s['team__items-item']}>

                        <h3>Martin</h3>
                        <span>Head of Development</span>
                        <p>Manages day to day tasks needed to
                            maintain our services and business</p>

                    </div>
                    <div className={s['team__items-item']}>

                        <h3>Philo</h3>
                        <span>Lead sales representative</span>
                        <p>Manages communication & customer satisfaction with our clients</p>
                        <a href="" className={s['team__items-item__discord']}>
                            <img src={LinkedIn} alt="LinkedIn"/>
                            <img src={Figure} alt="Figure"/>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Team;