import React from 'react';
import s from './Input.module.scss';
import classNames from "classnames";

interface IInput {
    icon?: string
    placeholder: string
    label?: string
    isSmall?: boolean
    id?: string
    readOnly?: boolean
    onChange?: any
    value?: string
    name?: string
}
const Input = ({icon, placeholder, label, isSmall, id, readOnly, onChange, value, name}: IInput) => {
    return (
        <div className={classNames(s.input, label && s.input_labeled, isSmall && s.input_small)}>
            {label && <span>{label}</span>}
            {icon && <img src={icon} alt="" className={s.input__icon}/>}
            <input type="text" name={name} placeholder={placeholder} id={id} readOnly={readOnly} value={value} onChange={onChange}/>
        </div>
    );
};

export default Input;