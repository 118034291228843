import React from 'react';
import s from './Button.module.scss';
import classNames from "classnames";
import { NavLink } from "react-router-dom";

interface IButton {
    text: string
    withBorder?: boolean
    isWhite?: boolean
    isBig?: boolean
    isGradient?: boolean
    className?: string
    onClick?: any
    disabled?: boolean
    rounded?: boolean
    navLink?: string
}
const Button = ({ text, withBorder, isBig, isWhite, isGradient, className, onClick, disabled, rounded, navLink }: IButton) => {
    if (navLink) {
        return (
            <NavLink to={navLink}>
                <button className={classNames(s.button, withBorder && s['button_border'], isBig && s['button_big'], isWhite && s['button_white'], rounded && s['button_rounded'], className)} onClick={onClick} disabled={disabled}>
                    <span>{text}</span>
                </button>
            </NavLink>
        )
    }
    return (
        <button className={classNames(s.button, withBorder && s['button_border'], isBig && s['button_big'], isWhite && s['button_white'], rounded && s['button_rounded'], className)} onClick={onClick} disabled={disabled}>
            <span>{text}</span>
        </button>
    );
};

export default Button;