import React from 'react';
import s from './Footer.module.scss';
import Logo from './../../assets/images/logo.png';
import {NavLink} from "react-router-dom";

const Footer = () => {
    return (
        <footer className={s.footer}>
            <div className={"container"}>
                <NavLink to={'/'} className={s.footer__logo}>
                    <img src={Logo} alt="Logo"/>
                </NavLink>
                <div className={s.footer__menu}>
                    <span>Useful Links</span>
                    <ul>
                        <li><NavLink to={'/'}>Home</NavLink></li>
                        <li><NavLink to={'/about'}>About Us</NavLink></li>
                        <li><NavLink to={'/services'}>Services</NavLink></li>
                    </ul>
                </div>

            </div>
        </footer>
    );
};

export default Footer;