import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Home from "./pages/Home/Home";
import Pay from "./pages/Pay/Pay";
import Login from "./pages/Login/Login";
import Signup from "./pages/Signup/Signup";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import Calendar from "./pages/Calendar/Calendar";
import PaymentHistory from "./pages/PaymentHistory/PaymentHistory";
import UpcomingBookings from "./pages/UpcomingBookings/UpcomingBookings";
import CustomerCalendar from "./pages/CustomerCalendar/CustomerCalendar";
import Contacts from "./pages/Contacts/Contacts";
import Services from "./pages/Services/Services";
import About from "./pages/About/About";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home/>,
    },
    {
        path: "/contacts",
        element: <Contacts/>,
    },
    {
        path: "/services",
        element: <Services/>,
    },
    {
        path: "/about",
        element: <About/>,
    },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
      <RouterProvider router={router}/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
