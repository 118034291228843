import React, {useEffect, useState} from 'react';
import s from './Header.module.scss';
import Logo from './../../assets/images/logo.png';
import Button from "../UI/Button/Button";
import classNames from "classnames";
import {NavLink} from "react-router-dom";

interface IHeader {
    isAlt?: boolean
}

const Header = ({isAlt}: IHeader) => {
    let [isMobile, setIsMobile] = useState(window.innerWidth < 1280);
    let [isOpened, setIsOpened] = useState(false);

    useEffect(() => {
        window.addEventListener('resize', () => setIsMobile(window.innerWidth < 1280));
        return () => {
            window.removeEventListener('resize', () => setIsMobile(window.innerWidth < 1280));
        }
    }, []);

    return (
        <header className={classNames(s.header, isOpened && s.header_opened, isAlt && s.header_alt)}>
            <div className="container">
                <NavLink to={'/'} className={s['header__logo']}>
                    <img src={Logo} alt=""/>
                </NavLink>
                {!isMobile ? <>
                    <ul className={s['header__menu']}>
                        <li className={classNames(s['header__menu-item'], s['header__menu-item_active'])}>
                            <NavLink to={'/'}>Home</NavLink>
                            {/*<div className={s['header__menu-item__dot']}>*/}
                            {/*    <div></div>*/}
                            {/*</div>*/}
                        </li>
                        <li className={classNames(s['header__menu-item'])}><NavLink to={'/about'}>About Us</NavLink></li>
                        <li className={classNames(s['header__menu-item'])}><NavLink to={'/services'}>Services</NavLink></li>
                        {/*<li className={classNames(s['header__menu-item'])}><a href="#">Pricing</a></li>*/}
                        <li className={classNames(s['header__menu-item'])}><NavLink to={'/contacts'}>Contact Us</NavLink></li>
                    </ul>
                    <div className={s['header__buttons']} style={{opacity: 0}}>
                        <Button text={'Login'} withBorder={true}/>
                        <Button text={'Signup'}/>
                    </div>
                </> : <>
                    <button className={classNames(s.header__burger, isOpened && s['header__burger_opened'])} onClick={() => setIsOpened(!isOpened)}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </button>
                    {isOpened && <ul className={s['header__menu']}>
                        <li className={classNames(s['header__menu-item'], s['header__menu-item_active'])}>
                            <NavLink to={'/'}>Home</NavLink>
                            {/*<div className={s['header__menu-item__dot']}>*/}
                            {/*    <div></div>*/}
                            {/*</div>*/}
                        </li>
                        <li className={classNames(s['header__menu-item'])}><NavLink to={'/about'}>About Us</NavLink></li>
                        <li className={classNames(s['header__menu-item'])}><NavLink to={'/services'}>Services</NavLink></li>
                        {/*<li className={classNames(s['header__menu-item'])}><a href="#">Pricing</a></li>*/}
                        <li className={classNames(s['header__menu-item'])}><NavLink to={'/contacts'}>Contact Us</NavLink></li>
                        {/*<li className={classNames(s['header__menu-item'])}><Button text={'Login'} withBorder={true}/></li>*/}
                        {/*<li className={classNames(s['header__menu-item'])}><Button text={'Signup'}/></li>*/}
                    </ul>}
                </>}
            </div>
        </header>
    );
};

export default Header;