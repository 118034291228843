import React, {useState} from 'react';
import s from './Contacts.module.scss';
import Phone from './../../assets/images/icons/phone.svg';
import Mail from './../../assets/images/icons/mail.svg';
import Location from './../../assets/images/icons/location.svg';
import User from './../../assets/images/icons/user.svg';
import Message from './../../assets/images/icons/message.svg';
import Building from './../../assets/images/icons/building.svg';
import Gear from './../../assets/images/icons/gear.svg';
import Hours from './../../assets/images/icons/24-hours.svg';
import Arrow from './../../assets/images/icons/arrow.svg';
import Input from "../UI/Input/Input";
import Button from "../UI/Button/Button";
import classNames from "classnames";
import {Formik} from "formik";

interface IContacts {
    isAlt?: boolean
}
const Contacts = ({isAlt}: IContacts) => {
    const [result, setResult] = useState("");

    return (
        <>
            <section className={classNames(s.contacts, isAlt && s.contacts_alt)}>
                <div className={"container"}>
                    <h2>Let's <span>Talk?</span></h2>
                    <p>We will respond to your message as soon as possible.</p>
                    <div className={s.contacts__block}>
                        <div className={s.contacts__info}>
                            <h2>Information</h2>
                            <div className={s['contacts__info-items']}>
                                <a href="#" className={s['contacts__info-items__item']}>
                                    <div>
                                        <img src={Phone} alt=""/>
                                    </div>
                                    <span>+61 481 364 014</span>
                                </a>
                                <a href="#" className={s['contacts__info-items__item']}>
                                    <div>
                                        <img src={Mail} alt=""/>
                                    </div>
                                    <span>contact@versatile.software</span>
                                </a>
                                <a href="#" className={s['contacts__info-items__item']}>
                                    <div>
                                        <img src={Location} alt=""/>
                                    </div>
                                    <span>Melbourne, Australia</span>
                                </a>
                            </div>
                            <img src={Arrow} alt="" className={s['contacts__info-arrow']}/>
                        </div>
                        <div className={s.contacts__form}>
                            <Formik
                                initialValues={{ name: '', email: '', phone: '', message: '' }}
                                validate={(values) => {}}
                                onSubmit={async (values, { setSubmitting }) => {
                                    setResult("Sending....");
                                    let formData = new FormData();
                                    formData.append('name', values.name);
                                    formData.append('email', values.email);
                                    formData.append('phone', values.phone);
                                    formData.append('message', values.message);
                                    formData.append('access_key', '6693e5d5-67a4-4bb3-8116-75a663a28605');

                                    let res = await fetch("https://api.web3forms.com/submit", {
                                        method: "POST",
                                        body: formData
                                    }).then((res) => res.json());

                                    if (res.success) {
                                        console.log("Success", res);
                                        setResult(res.message);
                                        //redirect to success page
                                        window.location.href = "https://web3forms.com/success";
                                    } else {
                                        console.log("Error", res);
                                        setResult(res.message);
                                    }

                                    setSubmitting(false);
                                }}
                            >
                                {({
                                      values,
                                      errors,
                                      touched,
                                      handleChange,
                                      handleBlur,
                                      handleSubmit,
                                      isSubmitting,
                                      /* and other goodies */
                                  }) => (
                                    <form onSubmit={handleSubmit}>
                                        <Input icon={User} onChange={handleChange} value={values.name} name={'name'} placeholder={'Enter you name'}/>
                                        <div className={s['contacts__form-row']}>
                                            <Input icon={Mail} onChange={handleChange} value={values.email} name={'email'} placeholder={'Enter email address'}/>
                                            <Input icon={Phone} onChange={handleChange} value={values.phone} name={'phone'} placeholder={'Phone number'}/>
                                        </div>
                                        <Input icon={Message} onChange={handleChange} value={values.message} name={'message'} placeholder={'Write message here...'}/>
                                        <Button disabled={isSubmitting} text={'Send Message'} isBig={true}/>
                                        <span>{result}</span>
                                    </form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </section>
            <section className={s.advantages}>
                <div className={"container"}>
                    <div className={s.advantages__items}>
                        <div className={s['advantages__items-item']}>
                            <div className={s['advantages__items-item__header']}>
                                <div>
                                    <img src={Building} alt=""/>
                                </div>
                                <h3>Company</h3>
                            </div>
                            <p>Established in 2021, Versatile Software has quickly become a trusted provider of high-quality products/services in our industry.</p>
                        </div>
                        <div className={s['advantages__items-item']}>
                            <div className={s['advantages__items-item__header']}>
                                <div>
                                    <img src={Gear} alt=""/>
                                </div>
                                <h3>Services</h3>
                            </div>
                            <p>Our company provides top-notch web design services that are tailored to meet the unique needs and preferences of our clients, helping them to establish a strong online presence.</p>
                        </div>
                        <div className={s['advantages__items-item']}>
                            <div className={s['advantages__items-item__header']}>
                                <div>
                                    <img src={Hours} alt=""/>
                                </div>
                                <h3>24h Support</h3>
                            </div>
                            <p>Our company takes pride in offering fast support to our customers, ensuring that their issues are resolved as quickly and efficiently as possible.</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Contacts;