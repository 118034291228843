import React, {useEffect, useState} from 'react';
import Header from "../../components/Header/Header";
import Hero from "../../components/Hero/Hero";
import Image1 from "../../assets/images/hero/image1.png";
import Button from "../../components/UI/Button/Button";
import Companies from "../../components/Companies/Companies";
import About from "../../components/About/About";
import Services from "../../components/Services/Services";
import Team from "../../components/Team/Team";
import Contacts from "../../components/Contacts/Contacts";
import Footer from "../../components/Footer/Footer";

const Home = () => {
    return (
        <>
            <Header/>
            <Hero>
                <h1>We build <span>Software</span>.</h1>
                <p>We work with the most innovative solutions on the market  to help you get the best results. Start today to take your company to the next level.</p>
                <Button text={"Let's Talk"} isBig={true} navLink={"/contacts"}/>
            </Hero>
            <Companies/>
            <About/>
            <Services/>
            <Team/>
            <Contacts/>
            <Footer/>
        </>
    );
};

export default Home;